
.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }

  .popup-content {
    position: relative;
    width: 40%;
    height: auto;
    background-color: #2c729b;
    border-radius: 10px;
    padding: 4%;
    padding-bottom: 0px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .popup-text {
    margin-bottom: 10px;
    color: white;
    font-family: Ploni, Arial, sans-serif;
    font-size: max(2em, 35px);
  }

  .popup-image {
    margin-top: auto;
    margin-bottom: 0;
    margin-right: 120px;
    height: 150px;
    width: 200px;
  }

  .popup-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }

  .popup-button {
    width: 202px;
    height: 40px;
    border-radius: 20px;
    border: none;
    color: #fff;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: max(1.2em, 35px);
    padding: 20px;
  }

  .add-button {
    background-color: orange;
    border: 2px solid #fff;
    margin-right: 10px;
    margin-left: 10px;
  }

  .no-thanks-button {
    background-color: #fff;
    border: 2px solid #25c4ee;
    color: #2c729b;
    margin-right: 10px;
    margin-left: 10px;
  }

  .popup-close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 50%;
    background-color: rgb(35, 106, 146);
    margin-bottom: 10px;
  }

  .popup-text-highlited {
    color: #25c4ee;
    font-size: max(2.2em, 35px);
  }

  .popup-close-x {
    color: #fff;
  }

  /* Media Queries */
  @media (max-width: 890px) {
    .popup-content {
      width: 90%;
    }
    .popup-image {
      margin-right: 0;
    }

    .popup-text {
      font-size: max(1.8em, 35px);
    }

    .popup-text-highlited {
      font-size: max(2em, 35px);
    }

    .popup-button {
      width:85%;
      font-size: max(0.9em, 9px);
    }
  }
  @media (max-height: 450px) {
    .popup-content {
      width: 75%;
      height: 70%;
    }
    .popup-image {
      opacity: 0;
    }

    .popup-text {
      font-size: max(0.5em, 20px);
    }

    .popup-text-highlited {
      font-size: max(0.5em, 25px);
    }

    .popup-button {
      justify-content: space-around;
      width:85%;
      font-size: max(0.7em, 5px);
    }
  }
